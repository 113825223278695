import React, { useEffect } from 'react'

const InView = ({ element, setToggle, children, location }) => {
  useEffect(() => {
    // Init a new observer
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (location === 'footer') {
            if (entry.intersectionRatio > 0) {
              setToggle(prevState => ({
                heroTrigger: prevState.heroTrigger,
                crackingTrigger: prevState.crackingTrigger,
                footerTrigger: true
              }))
            } else {
              setToggle(prevState => ({
                heroTrigger: prevState.heroTrigger,
                crackingTrigger: prevState.crackingTrigger,
                footerTrigger: false
              }))
            }
          }

          if (location === 'cracking') {
            if (entry.intersectionRatio > 0) {
              setToggle(prevState => ({
                heroTrigger: prevState.heroTrigger,
                crackingTrigger: true,
                footerTrigger: prevState.footerTrigger
              }))
            } else {
              setToggle(prevState => ({
                heroTrigger: prevState.heroTrigger,
                crackingTrigger: false,
                footerTrigger: prevState.footerTrigger
              }))
            }
          }

          if (location === 'hero') {
            if (entry.intersectionRatio > 0) {
              setToggle(prevState => ({
                heroTrigger: true,
                crackingTrigger: prevState.crackingTrigger,
                footerTrigger: prevState.footerTrigger
              }))
            } else {
              setToggle(prevState => ({
                heroTrigger: false,
                crackingTrigger: prevState.crackingTrigger,
                footerTrigger: prevState.footerTrigger
              }))
            }
          }
        })
      },
      {
        rootMargin: '10%',
        threshold: [0, 0.25, 0.5, 0.75, 1]
      }
    )

    if (element) {
      observer.observe(element)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, setToggle])

  return <>{children}</>
}

export default InView
