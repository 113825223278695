import React, { useRef, useState } from 'react'
import styled from 'styled-components'

export default function CopyExample({ text }) {
  const [copySuccess, setCopySuccess] = useState('Copy to clipboard...')
  const [isShown, setIsShown] = useState(false)
  const textAreaRef = useRef(null)

  const isCommandSupported =
    typeof document !== `undefined` ? document.queryCommandSupported('copy') : ''

  function copyToClipboard(e) {
    textAreaRef.current.select()
    if (typeof document !== `undefined`) {
      document.execCommand('copy')
    }
    e.target.focus()
    setCopySuccess('Copied!')

    setTimeout(() => {
      setIsShown(false)
      setCopySuccess('Copy to clipboard?')
    }, 3000)
  }

  return (
    <div>
      {isCommandSupported && (
        <WrapEmailCopy onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
          <EmailButton onClick={copyToClipboard}>{text}</EmailButton>
          {isShown && <SideInfoText>{copySuccess}</SideInfoText>}
        </WrapEmailCopy>
      )}
      <Form>
        <TextArea ref={textAreaRef} value={text} readOnly />
      </Form>
    </div>
  )
}

const Form = styled.form`
  position: absolute;
  left: -99999px;
`

const TextArea = styled.textarea`
  background-color: red;
`

const WrapEmailCopy = styled.div`
  position: relative;
  cursor: pointer;
  margin-bottom: 1rem;
`

const EmailButton = styled.div`
  background-color: transparent;
  border: 0;
  color: ${props => props.theme.colours.white} !important;
  font-family: ${props => props.theme.fonts.sofia} !important;
  font-size: 3.5rem !important;
  line-height: 0.9 !important;
  border-bottom: 0 !important;
  margin: 0 0 6rem 0;
  transition: color 0.15s ease-in-out;

  @media (min-width: 1920px) {
    font-size: 3rem !important;
  }

  @media (max-width: 650px) {
    font-size: 3.5rem !important;
    margin-bottom: 4.5rem;
  }

  @media (max-width: 350px) {
    font-size: 3rem !important;
  }

  &:hover {
    color: ${props => props.theme.colours.pink} !important;
  }
`

const SideInfoText = styled.h6`
  position: absolute;
  bottom: -40px;
  left: 0;
  height: 20px;
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 700;
  color: ${props => props.theme.colours.pink};
`
