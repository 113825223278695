import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { useInput } from './input-hook'

import {
  FormOuter,
  ContactInfos,
  SubmitWrapper,
  SubmitButtonOuter,
  SubmitButton,
  Form,
  FormBlock,
  FormSuccess,
  FormSuccessSignup,
  CloseIcon,
  CloseIconSignup
} from './styles'

const NewsletterForm = ({ content, fromSignup }) => {
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState(null)

  const { value: email, bind: bindEmail, reset: resetEmail } = useInput('')
  const { value: firstname, bind: bindFirstName, reset: resetFirstName } = useInput('')
  const { value: lastname, bind: bindLastName, reset: resetLastName } = useInput('')

  const handleFormSubmit = event => {
    event.preventDefault()
    resetEmail()
    resetFirstName()
    resetLastName()
    setLoading(true)

    // setResponseMessage('hello submit')
    // setSuccess(true)

    addToMailchimp(email, {
      FNAME: firstname,
      LNAME: lastname
    })
      .then(data => {
        setResponseMessage(data)
        setSuccess(true)
        setLoading(false)
      })
      .catch(() => {
        console.log('Request failure')
      })
  }

  return (
    <FormOuter>
      {loading && (
        <FormBlock>
          {fromSignup ? (
            <FormSuccessSignup className={loading ? 'active' : ''}>
              <p>Loading...</p>
            </FormSuccessSignup>
          ) : (
            <FormSuccess className={loading ? 'active' : ''}>
              <p>Loading...</p>
            </FormSuccess>
          )}
        </FormBlock>
      )}
      {success && (
        <FormBlock>
          {fromSignup ? (
            <FormSuccessSignup className={success ? 'active' : ''}>
              <p dangerouslySetInnerHTML={{ __html: responseMessage.msg }} />

              <CloseIconSignup
                aria-label="Toggle Navigation"
                onClick={() => {
                  setSuccess(false)
                }}
              >
                <p>Back to form</p>
              </CloseIconSignup>
            </FormSuccessSignup>
          ) : (
            <FormSuccess className={success ? 'active' : ''}>
              <p dangerouslySetInnerHTML={{ __html: responseMessage.msg }} />

              <CloseIcon
                aria-label="Toggle Navigation"
                onClick={() => {
                  setSuccess(false)
                }}
              >
                <p>Back to form</p>
              </CloseIcon>
            </FormSuccess>
          )}
        </FormBlock>
      )}
      <Form onSubmit={handleFormSubmit}>
        <input
          type="text"
          id="mce-FNAME"
          name="FNAME"
          placeholder="First name"
          required
          autoComplete="false-off"
          {...bindFirstName}
        />
        <input
          type="text"
          id="mce-LNAME"
          name="LNAME"
          placeholder="Last name"
          required
          {...bindLastName}
        />
        <SubmitWrapper>
          <input
            type="email"
            id="mce-EMAIL"
            name="EMAIL"
            placeholder="Email"
            required
            autoComplete="false-off"
            {...bindEmail}
          />
          <ContactInfos dangerouslySetInnerHTML={{ __html: content.contactInfos }} />
          <SubmitButtonOuter>
            <SubmitButton type="submit">Sign up</SubmitButton>
          </SubmitButtonOuter>
        </SubmitWrapper>
      </Form>
    </FormOuter>
  )
}

export default NewsletterForm
