// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-join-us-js": () => import("./../../../src/templates/join-us.js" /* webpackChunkName: "component---src-templates-join-us-js" */),
  "component---src-templates-privacy-js": () => import("./../../../src/templates/privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-signup-js": () => import("./../../../src/templates/signup.js" /* webpackChunkName: "component---src-templates-signup-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */),
  "component---src-templates-single-landing-js": () => import("./../../../src/templates/single-landing.js" /* webpackChunkName: "component---src-templates-single-landing-js" */),
  "component---src-templates-single-new-landing-js": () => import("./../../../src/templates/single-new-landing.js" /* webpackChunkName: "component---src-templates-single-new-landing-js" */),
  "component---src-templates-single-project-js": () => import("./../../../src/templates/single-project.js" /* webpackChunkName: "component---src-templates-single-project-js" */),
  "component---src-templates-single-service-js": () => import("./../../../src/templates/single-service.js" /* webpackChunkName: "component---src-templates-single-service-js" */),
  "component---src-templates-studio-js": () => import("./../../../src/templates/studio.js" /* webpackChunkName: "component---src-templates-studio-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

