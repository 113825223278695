// Reset the body colour on route change.
// Each page should start white but if the body background
// colour was previously set, it would persist across
// route changes.
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation !== null) {
    document.documentElement.style.setProperty('--transition', 'background-color 0s linear')
  }

  setTimeout(() => {
    document.documentElement.style.setProperty('--primary', '#373737')
    document.documentElement.style.setProperty('--secondary', '#ffffff')
  }, 50)

  setTimeout(() => {
    document.documentElement.style.setProperty(
      '--transition',
      'background-color 0.4s ease-in-out 0s'
    )
  }, 1000)
}
