import React from "react"
import { animated, useSprings, interpolate } from "react-spring"
import BezierEasing from "bezier-easing"
import { Link, navigate } from "gatsby"
import styled from "styled-components"

const Primary = ({
  primary,
  toggle,
  setToggle,
  setServicesToggle,
  setTransitionActive,
}) => {
  const primaryNav = primary[0].node.menuItems.edges

  const navItemsAnimation = useSprings(
    primaryNav.length,
    primaryNav.map((item, i) => ({
      y: toggle ? 0 : -30,
      rotate: toggle ? 0 : 5,
      opacity: toggle ? 1 : 0,
      from: { rotate: 5, y: -30, opacity: 0 },
      config: {
        duration: 500,
        easing: toggle
          ? BezierEasing(0.25, 0.46, 0.45, 0.94)
          : BezierEasing(1.0, 0.0, 0.7, 0.7),
      },
      delay: i * 100 + 100,
    }))
  )

  const showSubNav = () => {
    setServicesToggle(true)
  }

  const hideSubNav = () => {
    setServicesToggle(false)
  }

  return (
    <WrapPrimaryNav>
      {navItemsAnimation.map(({ rotate, y, ...rest }, index) => {
        const navItem = primary[0].node.menuItems.edges[index]
        const slug =
          navItem.node.connectedObject.slug === "home"
            ? ""
            : navItem.node.connectedObject.slug

        return (
          <animated.li
            key={[index]}
            style={{
              ...rest,
              transform: interpolate([y], y => `translateY(${y}px)`),
            }}
          >
            <Link
              activeClassName="active"
              onMouseEnter={slug === "services" ? showSubNav : hideSubNav}
              to={`/${slug}`}
              onClick={event => {
                event.preventDefault()
                setServicesToggle(false)
                setTransitionActive(true)
                setToggle(false)
                navigate(`/${slug}`)
                setTimeout(() => {
                  setTransitionActive(false)
                }, 300)
              }}
            >
              {navItem.node.connectedObject.title}
            </Link>
          </animated.li>
        )
      })}
    </WrapPrimaryNav>
  )
}

export default Primary

const WrapPrimaryNav = styled.ul`
  position: absolute;
  top: 50%;
  left: 20%;
  margin: 0;
  transform: translateY(-50%);

  @media (min-width: 1024px) and (max-height: 660px) {
    top: 45%;
  }

  @media (min-width: 1024px) and (max-height: 600px) {
    top: 42.5%;
  }

  @media (max-width: 650px) {
    left: calc(2.5% + 3rem);
  }

  li {
    list-style: none;
    overflow: hidden;
    padding-bottom: 2.2rem;
    margin-bottom: 0;
    will-change: transform, opacity;

    &:first-child {
      padding-top: 2.2rem;
    }

    a {
      display: block;
      font-size: 7.5rem;
      line-height: 0.8;
      letter-spacing: -0.08rem;
      color: rgba(0, 0, 0, 0.25);
      transition: color 300ms cubic-bezier(0.165, 0.84, 0.44, 1);

      @media (pointer: coarse) {
        transition: color 0s;
      }

      @media (max-width: 1024px) {
        font-size: 8.5rem;
        line-height: 1;
      }

      @media (max-width: 650px) {
        line-height: 1.1;
      }

      &:hover {
        color: rgba(255, 255, 255, 1);
      }

      &.active {
        color: ${props => props.theme.colours.white};
      }
    }
  }
`
