import React, { useContext, useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { animated } from 'react-spring'

import usePortal from 'react-useportal'
import CrossSvg from '../images/cross.svg'
import ContactForm from './contact/form'
import CopyToClipboard from '../helpers/copy-clipboard'
import { ButtonContext } from './context/button-context'
import InView from './in-view'

const GetCracking = ({ addTrigger = true }) => {
  const data = useStaticQuery(graphql`
    query GetCrackingQuery {
      wordPress {
        globalOptions {
          optionFields {
            contactInfos {
              emailProjects
              phone
              phoneNyc
            }
            startProject {
              ctaText
              formTitle
              introText
              sideText
            }
          }
        }
      }
    }
  `)

  const triggerRef = useRef()
  // eslint-disable-next-line no-unused-vars
  const [isDesktop, setIsDesktop] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [buttonTriggers, setButtonTriggers] = useContext(ButtonContext)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const { startProject } = data.wordPress.globalOptions.optionFields
  const startProjectContact = data.wordPress.globalOptions.optionFields.contactInfos

  const { openPortal, closePortal, isOpen, Portal } = usePortal({
    onOpen({ portal }) {
      portal.current.style.cssText = `
        position: absolute;
        height: 100vh;
        overflow: visible;
        z-index: 1000;
      `
    }
  })

  return (
    <CrackingWrap>
      <InView element={triggerRef.current} setToggle={setButtonTriggers} location="cracking">
        {addTrigger && <TriggerButton ref={triggerRef} />}
      </InView>
      <CrackingText>Let's get cracking</CrackingText>
      <CrackingSeparator></CrackingSeparator>
      <CrackingButton onClick={openPortal}> Start your project </CrackingButton>

      <Portal>
        <Modal className={isOpen ? 'active' : ''}>
          <ModalContents>
            <Close onClick={closePortal}>
              <CrossSvg />
            </Close>

            <WrapContent>
              <WrapLeft>
                <Title> {startProject.introText} </Title>

                <p>Contact us</p>
                <WrapEmail>
                  <CopyToClipboard text={startProjectContact.emailProjects} />
                </WrapEmail>
                <WrapPhone>
                  <span>London: </span>
                  <a href={`tel:${startProjectContact.phone}`}>{startProjectContact.phone}</a>
                </WrapPhone>
                <WrapPhone>
                  <span>New York: </span>
                  <a href={`tel:${startProjectContact.phoneNyc}`}>{startProjectContact.phoneNyc}</a>
                </WrapPhone>
              </WrapLeft>

              <WrapRight>
                <InsideWrap>
                  <h2>{startProject.formTitle}</h2>
                  <ContactForm />
                </InsideWrap>
              </WrapRight>
            </WrapContent>
          </ModalContents>
          <ModalBackground />
        </Modal>
      </Portal>
    </CrackingWrap>
  )
}

const CrackingWrap = styled.div`
  position: relative;
  padding: 5rem 15%;
  margin: 12rem auto 18rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12rem;

  @media (max-width: 1024px) {
    margin: 0 auto;
    padding: 2.5rem 10% 5rem 10%;
    flex-direction: row;
    width: 100%;
  }

  @media (max-width: 650px) {
    margin: 7.5rem auto;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 30px;
    height: auto;
  }
`

const CrackingText = styled.h5`
  font-size: 5rem;
  line-height: 0.8;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.sofia};
  display: inline-block;

  @media (max-width: 650px) {
    font-size: 4rem;
    text-align: center;
    line-height: 1.25;
    margin-bottom: 0;
    margin-bottom: 4rem;
  }
`

const CrackingSeparator = styled.span`
  background: ${props => props.theme.colours.pink};
  width: 1px;
  height: 10rem;
  margin: 0 3rem;
  display: inline-block;

  @media (max-width: 650px) {
    display: none;
  }
`

const CrackingButton = styled.button`
  background: ${props => props.theme.colours.pink};
  border: 0.2rem solid ${props => props.theme.colours.pink};
  padding: 2rem 3.5rem;
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.circular};
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 3rem;
  display: inline-block;
  cursor: pointer;
  transition: background 0.25s ease-in-out, color 0.25s ease-in-out, border 0.25s ease-in-out;

  &:hover {
    background: ${props => props.theme.colours.white};
    color: ${props => props.theme.colours.pink};
    border: 0.2rem solid ${props => props.theme.colours.pink};
  }

  @media (max-width: 1024px) {
    display: block;
    font-size: 1.7rem;
    border-radius: 5rem;
    padding: 2.5rem 4rem;
    margin-top: 0;
  }

  @media (max-width: 650px) {
    display: inline-block;
    font-size: 1.7rem;
    border-radius: 5rem;
    padding: 2.5rem 6rem;
  }
`

const ModalContents = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.9);
  transition: transform 0.3s ease;
  padding: 30px;
  z-index: 5;
`

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
`

const Modal = styled(animated.aside)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 100;
  overflow: hidden;

  &.active {
    opacity: 1;
    pointer-events: all;

    ${ModalContents} {
      transform: scale(1);
    }
  }
`

const Close = styled.button`
  position: absolute;
  top: 11.5rem;
  right: 3.4rem;
  width: 6.8rem;
  height: 3.3rem;
  transform: translateY(-50px);
  user-select: none;
  border: none;
  cursor: pointer;
  background: transparent;
  overflow: visible;
  z-index: 10;

  @media (min-width: 1700px) {
    top: 13rem;
  }

  @media (min-width: 1920px) {
    top: 10.9rem;
  }

  @media (max-width: 1024px) {
    top: 13.8rem;
  }

  @media (max-width: 650px) {
    top: 12rem;
  }

  svg {
    width: 100%;
    height: 100%;

    @media (min-width: 1920px) {
      width: 40px;
      height: 40px;
    }
  }
`

const WrapLeft = styled.div`
  width: 50%;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 15rem 15% 2.5rem 15%;
  }

  @media (max-width: 650px) {
    width: 100%;
    margin: 15rem 0 7.5rem 0;
  }

  p {
    font-size: 2rem;
    color: ${props => props.theme.colours.lightGrey};
    padding-top: 5rem;

    @media (max-width: 1024px) {
      padding-top: 2.5rem;
      font-size: 2rem;
    }

    @media (max-width: 650px) {
      padding-top: 2.5rem;
      font-size: 2.85rem;
    }
  }

  a {
    display: block;
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 3.4rem;

    @media (max-width: 1024px) {
      font-size: 3.4rem;
    }

    @media (max-width: 650px) {
      font-size: 3.8rem;
    }
  }
`

const Title = styled.h2`
  width: 80%;
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 4.5rem;
  color: ${props => props.theme.colours.pink};
`

const InsideWrap = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 10rem;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`

const WrapPhone = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 1.5;

  @media (max-width: 1224px) {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  a {
    font-size: 2.6rem !important;

    @media (min-width: 1920px) {
      font-size: 3.4rem !important;
    }

    @media (max-width: 1024px) {
      font-size: 2.8rem !important;
    }

    @media (max-width: 650px) {
      font-size: 3.8rem !important;
    }
  }

  @media (max-width: 650px) {
    margin-bottom: 2.5rem;
  }

  span {
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.grey};
    font-size: 2.6rem;

    @media (min-width: 1920px) {
      font-size: 3.4rem;
    }

    @media (max-width: 1024px) {
      font-size: 2.8rem;
    }

    @media (max-width: 650px) {
      font-size: 3.8rem;
    }
  }
`

const WrapEmail = styled.div`
  font-family: ${props => props.theme.fonts.sofia};
  color: ${props => props.theme.colours.pink};
  font-size: 3.4rem;
  margin-top: 3rem;

  @media (max-width: 1024px) {
    font-size: 5rem;
  }

  @media (max-width: 650px) {
    font-size: 3.8rem;
    margin-top: 4rem;
  }

  div {
    color: ${props => props.theme.colours.pink} !important;

    @media (max-width: 650px) {
      font-size: 3.8rem !important;
      margin-bottom: 2rem !important;
    }
  }
`

const WrapRight = styled.div`
  position: relative;
  width: 50%;

  @media (max-width: 1024px) {
    padding: 5rem 15% 15rem 15%;
    width: 100%;
  }

  @media (max-width: 650px) {
    padding: 7.5rem 0 15rem 0;
    width: 100%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background-color: ${props => props.theme.colours.grey};
    margin-right: 100px;
    display: block;

    @media (max-width: 1024px) {
      width: 100%;
      height: 1px;
      display: none;
    }

    @media (max-width: 650px) {
      width: 100%;
      height: 1px;
      display: block;
    }
  }

  h2 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 45px;

    @media (max-width: 1024px) {
      font-size: 5.5rem;
    }

    @media (max-width: 650px) {
      font-size: 30px;
    }
  }
`

const WrapContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  height: 80%;
  margin: 0 auto;

  @media (max-width: 1660px) and (max-height: 740px) {
    width: 85%;
    height: 95%;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 3rem;
  }

  @media (max-width: 1320px) and (max-height: 675px) {
    width: 85%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 0 3rem 0 3rem;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    position: relative;
    margin: 0 auto;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }
`

const TriggerButton = styled.div`
  position: absolute;
  top: 20%;
  width: 100%;
  height: 1px;
`

export default GetCracking
