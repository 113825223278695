import styled from 'styled-components'

export const FormOuter = styled.footer`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 66%;
  margin: 0 auto;

  input {
    border: 0;
    background: none;
    border-bottom: 1px solid ${props => props.theme.colours.white};
    font-family: ${props => props.theme.fonts.circular};
    font-size: 2rem;
    color: rgba(255, 255, 255, 1);
    margin-top: 3rem;
    transition: border 0.3s ease;
    padding: 0 0 1rem;

    @media (max-width: 1024px) {
      font-size: 16px;
    }

    &:focus {
      outline: none;
      border-bottom: 1px solid ${props => props.theme.colours.white};
    }

    &::placeholder {
      font-family: ${props => props.theme.fonts.circular};
      font-size: 2rem;
      color: ${props => props.theme.colours.white};
      opacity: 1;

      @media (max-width: 1024px) {
        font-size: 16px;
      }
    }

    &[type='text'] {
      width: 100%;
      border-radius: 0;
      &:first-child {
        margin-right: 3rem;
      }
    }

    &[type='email'] {
      position: relative;
      width: 100%;
      border-radius: 0;
      z-index: 1;
    }
  }
`

export const ContactInfos = styled.div`
  text-align: center;
  margin: 40px -25% 0;
  width: 150%;

  @media (max-width: 1024px) {
    margin: 40px auto 0;
    width: 100%;
  }

  p {
    color: ${props => props.theme.colours.white};
    font-family: ${props => props.theme.fonts.circular};
    font-size: 1.3rem;
    line-height: 1.3;
    opacity: 0.55;

    @media (max-width: 1024px) {
      font-size: 1.6rem;
    }
  }

  a {
    color: ${props => props.theme.colours.white};
    font-family: ${props => props.theme.fonts.circular};
    font-size: 1.3rem;
    line-height: 1.3;
    border-bottom: 1px solid ${props => props.theme.colours.white};

    @media (max-width: 1024px) {
      font-size: 1.6rem;
    }
  }
`

export const SubmitWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const SubmitButton = styled.button`
  background: transparent;
  border: 0.2rem solid ${props => props.theme.colours.white};
  padding: 1.8rem 3.25rem;
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.circular};
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1rem;
  border-radius: 3rem;
  margin-top: 3.5rem;
  display: inline-block;
  margin-left: 0;
  min-width: 20rem;
  text-align: center;
  transition: background-color 0.25s ease, color 0.25s ease;
  backface-visibility: hidden;
  cursor: pointer;

  @media (max-width: 1024px) {
    margin-top: 3rem;
    font-size: 1.5rem;
    border-radius: 5rem;
    padding: 2.5rem 6rem;
  }

  @media (max-width: 650px) {
    margin-top: 4.5rem;
    margin-left: 0;
    font-size: 1.7rem;
    border-radius: 5rem;
    padding: 2.5rem 6rem;
  }

  &:hover {
    background-color: ${props => props.theme.colours.white};
    color: ${props => props.theme.colours.pink};
  }
`

export const Form = styled.form`
  width: 80%;
  margin: 0 auto;

  @media (max-width: 650px) {
    width: 100%;
  }

  &.blur {
    background-color: ${props => props.theme.colours.pink};
    pointer-events: none;
  }
`

export const SubmitButtonOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`

export const FormBlock = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 3;
`

export const FormSuccess = styled.div`
  width: 200%;
  height: 100%;
  margin: 0 -50%;
  overflow: hidden;
  padding: 0 2rem;
  text-align: center;
  font-weight: 500;
  opacity: 0;
  background-color: ${props => props.theme.colours.pink};
  z-index: 5;

  &.active {
    opacity: 1;
  }

  @media (max-width: 1024px) {
    width: 120%;
    height: 100%;
    margin: 30px -10% 0 -10%;
  }

  p {
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.white};
    font-size: 4rem;
    line-height: 1.2;
    text-align: center;
    margin: 40px 0 0 0;

    @media (max-width: 650px) {
      margin: 20px 0 0 0;
      font-size: 3rem;
    }

    a {
      font-family: ${props => props.theme.fonts.sofia};
      color: ${props => props.theme.colours.white};
      pointer-events: all;
    }
  }
`

export const FormSuccessSignup = styled.div`
  width: 200%;
  height: 100%;
  margin: 0 -50%;
  overflow: hidden;
  padding: 0 2rem;
  text-align: center;
  font-weight: 500;
  opacity: 0;
  background-color: ${props => props.theme.colours.white};
  z-index: 5;

  &.active {
    opacity: 1;
  }

  @media (max-width: 1024px) {
    width: 120%;
    height: 100%;
    margin: 30px -10% 0 -10%;
  }

  p {
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.pink};
    font-size: 4rem;
    line-height: 1.2;
    text-align: center;
    margin: 40px 0 0 0;

    @media (max-width: 650px) {
      margin: 20px 0 0 0;
      font-size: 3rem;
    }

    a {
      font-family: ${props => props.theme.fonts.sofia};
      color: ${props => props.theme.colours.pink};
      pointer-events: all;
    }
  }
`

export const CloseIcon = styled.button`
  position: absolute;
  bottom: -50px;
  left: 50%;
  width: 300px;
  height: auto;
  text-align: center;
  border: 0;
  cursor: pointer;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  z-index: 150;
  margin-top: 0;
  transform: translateX(-50%);

  @media (max-width: 650px) {
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }

  p {
    font-family: ${props => props.theme.fonts.circular};
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    color: ${props => props.theme.colours.white};
    margin-top: 40px;

    @media (max-width: 650px) {
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
`

export const CloseIconSignup = styled.button`
  position: absolute;
  bottom: -50px;
  left: 50%;
  width: 300px;
  height: auto;
  text-align: center;
  border: 0;
  cursor: pointer;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  z-index: 150;
  margin-top: 0;
  transform: translateX(-50%);

  @media (max-width: 650px) {
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }

  p {
    font-family: ${props => props.theme.fonts.circular};
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    color: ${props => props.theme.colours.pink};
    margin-top: 40px;

    @media (max-width: 650px) {
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
`
