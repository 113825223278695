import React, { useState, useEffect, useContext } from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import BezierEasing from 'bezier-easing'
import { BannerContext } from './context/banner-context'
import { TransitionContext } from './context/transition-context'

import GetCracking from './get-cracking'

import PrimaryNav from './menu/primary'
import SecondaryNav from './menu/secondary'
import SocialNav from './menu/social'

const Menu = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      wordPress {
        menus {
          edges {
            node {
              name
              slug
              menuItems {
                edges {
                  node {
                    connectedObject {
                      ... on WordPress_Page {
                        slug
                        title
                      }
                      ... on WordPress_Service {
                        slug
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
        services(where: { orderby: { field: MENU_ORDER, order: ASC } }) {
          edges {
            node {
              title
              slug
            }
          }
        }
        globalOptions {
          optionFields {
            contactInfos {
              address
              directionLink
              directionText
              email
              facebookLink
              instagramLink
              linkedinLink
              phone
              twitterLink
            }
            header {
              backgroundImage {
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            logo {
              imageWhite {
                sourceUrl
                mediaItemId
                modified
                altText
                imageFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  const [toggle, setToggle] = useState(false)
  const [servicesToggle, setServicesToggle] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [bannerActive, setBannerActive] = useContext(BannerContext)
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const [visible, setVisible] = useState(true)

  // Animation for the logo inside the nav
  const logoAnimation = useSpring({
    opacity: toggle ? 1 : 0,
    from: {
      opacity: 0
    },
    config: {
      duration: toggle ? 600 : 500
    },
    delay: toggle ? 50 : 1000
  })

  // Animation for the get crack area, bottom-right of the menu
  const springCracking = useSpring({
    transform: toggle ? `translate3d(0, 0px, 0)` : `translate3d(0, -13.5%, 0)`,
    opacity: toggle ? 1 : 0,
    from: {
      opacity: 0,
      transform: `translate3d(0, -13.5%, 0)`
    },
    config: {
      duration: toggle ? 800 : 400,
      easing: toggle ? BezierEasing(0.3, 0.3, 0, 1) : BezierEasing(1.0, 0.0, 0.7, 0.7)
    },
    delay: toggle ? 300 : 0
  })

  // Aniamtion for the background
  const navAnimation = useSpring({
    transform: toggle ? `translate3d(0, 0px, 0)` : `translate3d(0, -100%, 0)`,
    from: {
      transform: `translate3d(0, -100%, 0)`
    },
    config: {
      duration: toggle ? 600 : 800,
      easing: toggle ? BezierEasing(0.165, 0.84, 0.44, 1.0) : BezierEasing(1.0, 0.0, 0.7, 0.7)
    },
    delay: toggle ? 0 : 500
  })

  const navIconAnim = useSpring({
    // transform: 'scale(1)',
    opacity: 1,
    from: {
      // transform: 'scale(0.75)',
      opacity: 0
    },
    delay: 500
  })

  const logo = data.wordPress.globalOptions.optionFields.logo.imageWhite.imageFile.publicURL
  const logoAlt = data.wordPress.globalOptions.optionFields.logo.imageWhite.altText

  const socialLink = data.wordPress.globalOptions.optionFields.contactInfos

  const navItems = data.wordPress.menus.edges

  const mainNav = navItems.filter(mainNavItem => mainNavItem.node.name === 'Primary Menu')

  return (
    <>
      <NavIcon
        style={navIconAnim}
        aria-label="Toggle Navigation"
        className={` ${toggle ? 'active' : ''} ${bannerActive ? 'banner-is-here' : ''}`}
        onClick={() => {
          setToggle(!toggle)
          setServicesToggle(false)
        }}
      >
        <Lines />
      </NavIcon>
      <MenuOuter className={toggle ? 'active' : ''}>
        <BgNav style={navAnimation} />

        <KotaLogo style={logoAnimation}>
          <Link
            to="/"
            onClick={event => {
              event.preventDefault()
              setServicesToggle(false)
              setToggle(false)
              navigate(`/`)
            }}
          >
            <img src={logo} alt={logoAlt} />
          </Link>
        </KotaLogo>

        <PrimaryNav
          primary={mainNav}
          toggle={toggle}
          setToggle={setToggle}
          setServicesToggle={setServicesToggle}
          setTransitionActive={setTransitionActive}
        />

        <SecondaryNav
          secondary={data.wordPress.services.edges}
          setToggle={setToggle}
          setServicesToggle={setServicesToggle}
          servicesToggle={servicesToggle}
          setTransitionActive={setTransitionActive}
        />

        <WrapBottomMenu>
          <SocialNav social={socialLink} toggle={toggle} setToggle={setToggle} />

          <WrapCracking style={springCracking}>
            <GetCracking addTrigger={false} />
          </WrapCracking>
        </WrapBottomMenu>
      </MenuOuter>
    </>
  )
}

export default Menu

const MenuOuter = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  pointer-events: none;
  z-index: 110 !important;

  &.active {
    pointer-events: all;
  }
`
const BgNav = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background-color: ${props => props.theme.colours.pink};
  will-change: transform;
`

const KotaLogo = styled(animated.div)`
  position: absolute;
  top: 5rem;
  left: 5rem;

  @media (max-width: 650px) {
    width: 50px;
    top: 4.5rem;
    left: 3.5rem;
  }

  img {
    width: 60px;
    margin: 0;

    @media (min-width: 1700px) {
      width: 90px;
      height: 65.5px;
    }

    @media (max-width: 650px) {
      width: 45px;
    }
  }

  svg {
    width: 60px;
    transition: transform 0.25s ease;
    margin-bottom: 0;
    mix-blend-mode: multiply;

    @media (min-width: 1700px) {
      width: 90px;
    }
    &:hover {
      transform: scale(1.075);
    }

    @media (max-width: 650px) {
      width: 45px;
    }
  }
`

const Lines = styled.span`
  display: inline-block;
  cursor: pointer;
  user-select: none;
  transition: top 300ms ease, transform 300ms ease, background-color 300ms ease;
  width: 3.5rem;
  height: 0.35rem;
  background-color: ${props => props.theme.colours.white};
  position: relative;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    width: 3.5rem;
    height: 0.35rem;
    background-color: ${props => props.theme.colours.white};
    position: absolute;
    right: 0;
    transform-origin: 50% 50%;
    transition: top 300ms cubic-bezier(0.165, 0.84, 0.44, 1),
      transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1),
      background-color 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
    mix-blend-mode: multiply;
  }

  &:before {
    top: 1rem;
    width: 2.2rem;
  }

  &:after {
    top: -1rem;
  }

  @media (max-width: 1024px) {
    width: 4rem;
    height: 0.35rem;

    &:before {
      width: 3rem;
      height: 0.35rem;
      top: 1.25rem;
    }

    &:after {
      width: 4rem;
      height: 0.35rem;
      top: -1.25rem;
    }
  }

  @media (max-width: 650px) {
    width: 5rem;
    height: 0.4rem;

    &:before {
      width: 4rem;
      height: 0.4rem;
      top: 1.5rem;
    }

    &:after {
      width: 5rem;
      height: 0.4rem;
      top: -1.5rem;
    }
  }

  @media (min-width: 1900px) {
    width: 3rem;
    height: 0.35rem;

    &:before {
      width: 2rem;
      height: 0.35rem;
    }

    &:after {
      width: 3rem;
      height: 0.35rem;
    }
  }
`

const NavIcon = styled(animated.button)`
  position: fixed;
  top: 11.75rem;
  right: 3.4rem;
  width: 6.8rem;
  height: 3.3rem;
  transform: translateY(-50px);
  user-select: none;
  border: none;
  cursor: pointer;
  background: transparent;
  z-index: 120;
  margin-top: 0;
  mix-blend-mode: exclusion;
  transition: margin-top 0.2s ease-in-out;

  &.banner-is-here {
    margin-top: 2rem;
  }

  @media (min-width: 1700px) {
    top: 11.75rem;
  }

  @media (min-width: 1920px) {
    top: 10rem;
  }

  @media (max-width: 1024px) {
    top: 13.8rem;
  }

  @media (max-width: 650px) {
    top: 13.5rem;
    padding: 0;
    right: 18px;
  }

  &:hover {
    ${Lines}::before {
      transform: translateY(2px);
    }
    ${Lines}::after {
      transform: translateY(-2px);
    }
  }

  &.active {
    mix-blend-mode: unset !important;

    ${Lines} {
      background: transparent;
      &:before,
      &:after {
        transition: top 300ms cubic-bezier(0.165, 0.84, 0.44, 1),
          transform 300ms 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
        background-color: ${props => props.theme.colours.white};
        top: 0;
        width: 3.5rem;
        height: 5px;
      }
      &:before {
        transform: rotate3d(0, 0, 1, 45deg) scale(1.1);
      }
      &:after {
        transform: rotate3d(0, 0, 1, -45deg) scale(1.1);
      }
    }
  }
`

const WrapBottomMenu = styled.div`
  position: absolute;
  bottom: 2%;
  width: calc(100% - 10rem);
  height: 12rem;
  left: 5rem;
  display: flex;
  align-items: center;

  @media (max-width: 650px) {
    width: calc(100% - (2.5% + 3rem));
    left: calc(2.5% + 3rem);
  }
`

const WrapCracking = styled(animated.div)`
  z-index: 50;
  position: absolute;
  bottom: 5%;
  right: 0;

  @media (max-width: 1024px) {
    display: block;

    h5 {
      display: none;
    }

    span {
      display: none;
    }
  }

  @media (max-width: 650px) {
    display: none;
  }

  & > div:first-of-type {
    padding: 0;
    margin: 0;
  }

  span {
    background-color: ${props => props.theme.colours.white};
  }

  h5 {
    color: ${props => props.theme.colours.white} !important;
    font-size: 2.6rem;
  }

  button {
    background-color: rgba(0, 0, 0, 0.25);
    color: ${props => props.theme.colours.white};
    transition: color 0.2s ease-in-out;

    &:hover {
      background-color: ${props => props.theme.colours.white};
      color: ${props => props.theme.colours.pink};
    }
  }
`
