import React, { useState, createContext } from 'react'

export const ButtonContext = createContext()

export const ButtonProvider = ({ children }) => {
  const [buttonTriggers, setButtonTriggers] = useState({
    heroTrigger: false,
    crackingTrigger: false,
    footerTrigger: false
  })

  return (
    <ButtonContext.Provider value={[buttonTriggers, setButtonTriggers]}>
      {children}
    </ButtonContext.Provider>
  )
}
