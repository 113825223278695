import React, { useState, useEffect, useRef } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { useInput } from './input-hook'
import FileIcon from '../../images/file_icon.svg'

import Checkbox from './checkbox'

const ContactForm = ({ data }) => {
  const [checkbox, setcheckbox] = useState(false)
  const [checkboxSign, setcheckboxSign] = useState(false)
  const [success, setSuccess] = useState(false)
  const [pageTitle, setPageTitle] = useState(null)
  const [pageUrl, setPageUrl] = useState(null)
  const [referrerData, setreferrerData] = useState(null)
  const [successResponse, setSuccessResponse] = useState(null)
  const [uploadText, setUploadText] = useState('Upload File [pdf, jpeg, docx, ppt]')
  const handleCheckboxChange = () => setcheckbox(!checkbox)
  const handleCheckboxSignChange = () => setcheckboxSign(!checkboxSign)
  const inputFile = useRef()

  const username = data.site.siteMetadata.wp_user
  const password = data.site.siteMetadata.wp_pass

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setPageTitle(document.title)
      setPageUrl(window.location.href)

      if (localStorage.getItem('referrer')) {
        setreferrerData('PPC')
      } else {
        setreferrerData(document.referrer)
      }
    }
  }, [pageTitle, pageUrl, referrerData])

  const date = new Date().getTime()

  const { transform, opacity } = useSpring({
    opacity: successResponse ? 1 : 0,
    transform: `translateX(${successResponse ? 180 : 0})`
  })

  const { value: email, bind: bindEmail, reset: resetEmail } = useInput('')
  const { value: firstname, bind: bindFirstName, reset: resetFirstName } = useInput('')
  const { value: lastname, bind: bindLastName, reset: resetLastName } = useInput('')
  const { value: message, bind: bindMessage, reset: resetMessage } = useInput('')

  const handleFormSubmit = event => {
    event.preventDefault()
    resetEmail()
    // resetFile()
    resetFirstName()
    resetLastName()
    resetMessage()

    const portalId = '1645142'
    const formGuid = '6eca2d0c-5da8-473a-9823-0b26fc237347'
    const endpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`
    const mediaEndpoint = `https://content.kota.co.uk/wp-json/wp/v2/media`

    const postToHubspot = fileUrl => {
      const formData = JSON.stringify({
        submittedAt: `${date}`,
        fields: [
          { name: 'email', value: `${email}` },
          { name: 'firstname', value: `${firstname}` },
          { name: 'lastname', value: `${lastname}` },
          { name: 'file', value: fileUrl ? `${fileUrl}` : '' },
          { name: 'message', value: `${message}` },
          { name: 'referrer', value: `${referrerData || ''}` },
          { name: 'signup', value: `${checkboxSign}` }
        ],
        context: {
          pageUri: pageUrl,
          pageName: pageTitle
        },
        legalConsentOptions: {
          consent: {
            // Include this object when GDPR options are enabled
            consentToProcess: true,
            text: 'I agree to allow Kota creative to store and process my personal data.',
            communications: [
              {
                value: true,
                subscriptionTypeId: 999,
                text: 'I agree to receive marketing communications from Example Company.'
              }
            ]
          }
        },
        skipValidation: true
      })

      fetch(endpoint, {
        method: 'POST',
        headers: {
          // Authorization: `Basic ${encode(`${username}:${password}`)}`,
          'Content-Type': 'application/json'
        },
        body: formData
      })
        .then(response => response.json())
        .then(responseData => {
          setSuccess(true)
          setSuccessResponse(responseData.inlineMessage)
        })
        .catch(function(error) {
          console.log('Request failure: ', error)
        })

      if (checkboxSign) {
        addToMailchimp(email, {
          FNAME: firstname,
          LNAME: lastname
        })
          .then(data => {
            console.log('Posted to Mailchimp')
          })
          .catch(() => {
            console.log('Request failure')
          })
      }
    }

    if (inputFile.current.files.length > 0) {
      console.log('posting Headless + hubspot')
      const formMediaData = new FormData()
      formMediaData.append('file', inputFile.current.files[0])

      fetch(mediaEndpoint, {
        method: 'POST',
        headers: {
          Authorization: `Basic ${window.btoa(`${username}:${password}`)}`
        },
        body: formMediaData
      })
        .then(res => res.json())
        .then(data => {
          postToHubspot(data.source_url)
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      postToHubspot()
    }
  }

  const handleChange = event => {
    setUploadText(`${event.target.files[0].name}`)
  }

  return (
    <Outer>
      <Inner className="container">
        <FormWrap>
          {success && (
            <FormBlock>
              <FormSuccess
                initial="hidden"
                style={{
                  opacity,
                  transform: transform.interpolate(t => `${t} translateX(180deg)`)
                }}
                exit="exit"
                dangerouslySetInnerHTML={{ __html: successResponse }}
              ></FormSuccess>
            </FormBlock>
          )}
          <FormHTML onSubmit={handleFormSubmit} className={success ? 'blur' : ''}>
            <label className="first-name-label">
              <input
                type="text"
                id="firstname"
                name="firstname"
                placeholder="First Name"
                required
                {...bindFirstName}
              />
            </label>
            <label className="last-name-label">
              <input
                type="text"
                id="lastname"
                name="lastname"
                placeholder="Last Name"
                required
                {...bindLastName}
              />
            </label>
            <label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                required
                {...bindEmail}
              />
            </label>
            <label className="label-file">
              <p>{uploadText}</p>
              <input
                ref={inputFile}
                placeholder="Upload File"
                type="file"
                accept="image/*,.pdf,.doc,.docx"
                onChange={handleChange}
              />
              <div className="hidden-icon">
                <FileIcon />
              </div>
              <FileIcon />
            </label>
            <label>
              <textarea
                id="message"
                name="message"
                placeholder="Message"
                required
                {...bindMessage}
              />
            </label>
            <CheckboxOuterSign>
              <Checkbox
                checked={checkboxSign}
                onChange={handleCheckboxSignChange}
                text="I'm happy to receive a seriously cool monthly newsletter from KOTA."
              />
            </CheckboxOuterSign>
            <CheckboxOuter>
              <Checkbox
                checked={checkbox}
                required
                onChange={handleCheckboxChange}
                text="I understand that KOTA will securely hold my data in accordance with their privacy policy."
              />
            </CheckboxOuter>
            <SubmitBtn type="submit">submit</SubmitBtn>
          </FormHTML>
        </FormWrap>
      </Inner>
    </Outer>
  )
}

// export default ContactForm

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            wp_user
            wp_pass
          }
        }
      }
    `}
    render={data => <ContactForm data={data} {...props} />}
  />
)

const FormSuccess = styled(animated.div)`
  width: 100%;
  overflow: hidden;
  max-width: 36rem;
  padding: 0 2rem;
  text-align: center;
  margin: 0;
  z-index: 5;

  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.4;
    text-align: center;
  }
`

const FormBlock = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
`

const FormWrap = styled.div`
  position: relative;
  z-index: 1;
`

const Outer = styled(animated.section)`
  position: relative;
  padding: 5rem 0;
  z-index: 5;

  @media (max-width: 1024px) {
    padding: 2.5rem 0;
  }
`

const Inner = styled.div`
  max-width: 80%;
  padding: 0;
  margin-left: 0;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`

const CheckboxOuter = styled.label`
  display: flex;
  margin: 0 !important;
  cursor: pointer;
`

const CheckboxOuterSign = styled.label`
  display: flex;
  margin: 0 0 10px 0 !important;
  cursor: pointer;
`

const FormHTML = styled.form`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  transition: filter 0.3s ease;
  z-index: 1;

  &.blur {
    filter: blur(5px);
    pointer-events: none;
  }

  > label {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2.5rem;
    font-size: 1.6rem;
    font-weight: 100;
    letter-spacing: 0.1rem;
    color: ${props => props.theme.colours.grey};

    @media (max-width: 1280px) {
      margin-bottom: 1rem;
    }

    &:focus-within {
      span {
        transform: scale(0.6);
      }
    }
    span {
      transition: transform 0.3s ease;
      transform-origin: bottom left;
    }

    &.label-file {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row !important;
      justify-content: space-between;
      margin: 1rem 0 2.5rem 0;
      background: transparent;
      font-size: 1.6rem !important;
      cursor: pointer;
      border-bottom: 0.1rem solid ${props => props.theme.colours.grey};
      overflow: hidden;

      svg {
        position: absolute;
        bottom: 10px;
        right: 0;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        p {
          color: ${props => props.theme.colours.pink};
          opacity: 1;
        }

        svg {
          bottom: 50px;
          opacity: 0;
        }

        .hidden-icon {
          bottom: 10px;

          svg {
            opacity: 1;
            bottom: 0;
          }
        }
      }

      p {
        width: 70%;
        background: transparent;
        border: 0;
        font-size: 1.6rem !important;
        font-weight: 100;
        letter-spacing: 0.1rem;
        color: ${props => props.theme.colours.grey};
        padding: 10px 0 10px 5px;
        margin: 0;
        transition: all 0.3s ease-in-out;
        opacity: 0.6;

        @media (max-width: 1024px) {
          font-size: 2rem;
        }

        @media (max-width: 650px) {
          font-size: 2.3rem;
        }
      }

      input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      .hidden-icon {
        position: absolute;
        bottom: -50px;
        right: 0;
        width: 20px;
        height: 20px;
        transition: all 0.3s ease-in-out;

        svg {
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }
      }
    }

    &.first-name-label {
      width: 47.5%;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    &.last-name-label {
      width: 47.5%;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  ${CheckboxOuter} {
    width: 100%;
  }

  input {
    border: 0;
    color: ${props => props.theme.colours.grey};
    padding: 5px 0;

    @media (max-width: 1024px) {
      padding: 10px 0;
    }

    &:focus {
      outline: 0;
    }
    &[type='text'],
    &[type='email'] {
      font-size: 1.6rem;
      font-weight: 100;
      letter-spacing: 0.1rem;
      border-radius: 0;
      background: transparent;
      border-bottom: 0.1rem solid ${props => props.theme.colours.grey};
      margin-top: 1rem;
      transition: border 0.3s ease;

      &:focus {
        border-bottom: 0.1rem solid ${props => props.theme.colours.grey};
      }

      @media (max-width: 1024px) {
        font-size: 2rem;
      }

      @media (max-width: 650px) {
        font-size: 2.3rem;
      }
    }
  }

  textarea {
    height: 100px;
    padding: 10px 0 10px 5px;
    margin: 1rem 0 0 0;
    letter-spacing: 0.1rem;
    border-radius: 0;
    background: transparent;
    border-bottom: 0.1rem solid ${props => props.theme.colours.grey};
    outline-color: ${props => props.theme.colours.grey};
    color: ${props => props.theme.colours.grey};

    @media (max-width: 1024px) {
      font-size: 2rem;
    }

    @media (max-width: 650px) {
      font-size: 2.3rem;
    }

    &:placeholder {
      opacity: 0.5;
    }
  }
`

const SubmitBtn = styled.button`
  border: 2px solid ${props => props.theme.colours.pink};
  border-radius: 40px;
  background-color: transparent;
  color: ${props => props.theme.colours.pink};
  text-transform: uppercase;
  font-family: ${props => props.theme.fonts.circular};
  letter-spacing: 0.1rem;
  font-weight: 700;
  padding: 20px 70px;
  font-size: 1.2rem;
  position: absolute;
  bottom: -22%;
  right: 0;
  cursor: pointer;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${props => props.theme.colours.pink};
    color: ${props => props.theme.colours.white};
  }

  @media (max-width: 1024px) {
    font-size: 1.75rem;
  }

  @media (max-width: 650px) {
    left: 0;
    font-size: 1.75rem;
  }
`
