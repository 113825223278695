import React, { useEffect, useCallback } from 'react'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import GlobalStyle from './global-styles'
import { TransitionProvider } from './context/transition-context'
import { ButtonProvider } from './context/button-context'
import { BannerProvider } from './context/banner-context'
import { NewsletterProvider } from './context/newsletter-context'

import Transition from './transiton'
import Header from './header'
import Menu from './menu'
import Newsletter from './newsletter'

const PageWrap = ({ children }) => {
  const updateVh = useCallback(() => {
    if (typeof window !== `undefined`) {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }, [])

  useEffect(() => {
    updateVh()

    window.addEventListener('resize', updateVh)

    return () => {
      window.removeEventListener('resize', updateVh)
    }
  }, [updateVh])

  return (
    <ThemeProvider theme={theme}>
      <TransitionProvider>
        <ButtonProvider>
          <BannerProvider>
            <NewsletterProvider>
              <GlobalStyle />
              <Transition />
              <Menu />
              <Header />
              <Newsletter/>
              {children}
            </NewsletterProvider>
          </BannerProvider>
        </ButtonProvider>
      </TransitionProvider>
    </ThemeProvider>
  )
}

export default PageWrap
