import React from 'react'
import { animated, useSprings, interpolate } from 'react-spring'
import BezierEasing from 'bezier-easing'
import styled from 'styled-components'

import FacebookLogo from '../../images/facebook.svg'
import InstaLogo from '../../images/instagram.svg'
import TwitterLogo from '../../images/twitter.svg'
import LinkedinLogo from '../../images/linkedin.svg'

const Social = ({ social, toggle, setToggle }) => {
  const socialLinkArray = [
    ['twitter', social.twitterLink],
    ['instagram', social.instagramLink],
    ['linkedin', social.linkedinLink],
    ['facebook', social.facebookLink]
  ]

  const socialAnimation = useSprings(
    socialLinkArray.length,
    socialLinkArray.map((item, i) => ({
      y: toggle ? 0 : -30,
      rotate: toggle ? 0 : 5,
      opacity: toggle ? 1 : 0,
      from: { rotate: 5, y: -30, opacity: 0 },
      config: {
        duration: 500,
        easing: toggle ? BezierEasing(0.25, 0.46, 0.45, 0.94) : BezierEasing(1.0, 0.0, 0.7, 0.7)
      },
      delay: i * 100 + 200
    }))
  )

  const SwitchsocialIcon = videoType => {
    /* eslint-disable */
      switch(videoType) {
        case 'twitter':
          return (
            <TwitterLogo />
          )
          break;
        case 'instagram':
          return (
            <InstaLogo />
          )
          break;
        case 'linkedin':
          return (
            <LinkedinLogo />
          )
          break;
        case 'facebook':
          return (
            <FacebookLogo />
          )
          break;
        default:
          return null;
      }
      /* eslint-enable */
  }

  return (
    <WrapSocials>
      {socialAnimation.map(({ rotate, y, ...rest }, index) => (
        <animated.a
          href={socialLinkArray[index][1]}
          target="_blank"
          rel="noopener noreferrer"
          key={[index]}
          style={{
            ...rest,
            transform: interpolate([y], y => `translateY(${y}px)`)
          }}
        >
          {SwitchsocialIcon(socialLinkArray[index][0])}
        </animated.a>
      ))}
    </WrapSocials>
  )
}

export default Social

const WrapSocials = styled.div`
  position: absolute;
  left: 0;
  margin-top: -1rem;

  @media (max-width: 1024px) {
    left: 0;
    display: flex;
    justify-content: space-around;
    width: 60%;
    max-width: 250px;
    margin: 0;
    bottom: unset;
    position: relative;
  }

  @media (max-width: 650px) {
    position: relative;
    left: 0;
    transform: translateX(0);
  }

  a {
    margin-left: 2rem;
    max-width: 20px;

    @media (max-width: 1024px) {
      max-width: 100%;
      margin-left: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  svg {
    fill: transparent;
    width: 40px;

    g {
      stroke: ${props => props.theme.colours.white};
      fill: transparent;
    }

    path {
      fill: ${props => props.theme.colours.white};
    }
  }
`
