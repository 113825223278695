import React, { useContext } from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import BezierEasing from 'bezier-easing'
import { NewsletterContext } from './context/newsletter-context'
import NewsletterForm from './newsletter-form/index.js'

const Newsletter = () => {
  const data = useStaticQuery(graphql`
    query NewsletterQuery {
      wordPress {
        globalOptions {
          optionFields {
            newsletter {
              contactInfos
              title
            }
          }
        }
      }
    }
  `)

  // eslint-disable-next-line no-unused-vars
  const [newsletterTriggers, setNewsletterTriggers] = useContext(NewsletterContext)

  // Aniamtion for the background
  const bgAnimation = useSpring({
    transform: newsletterTriggers ? `translate3d(0, 0px, 0)` : `translate3d(0, -100%, 0)`,
    from: {
      transform: `translate3d(0, -100%, 0)`
    },
    config: {
      duration: newsletterTriggers ? 600 : 800,
      easing: newsletterTriggers
        ? BezierEasing(0.165, 0.84, 0.44, 1.0)
        : BezierEasing(1.0, 0.0, 0.7, 0.7)
    },
    delay: newsletterTriggers ? 0 : 600
  })

  const closeIconAnim = useSpring({
    opacity: newsletterTriggers ? 1 : 0,
    from: {
      opacity: 0
    },
    config: {
      duration: newsletterTriggers ? 600 : 800,
      easing: newsletterTriggers
        ? BezierEasing(0.165, 0.84, 0.44, 1.0)
        : BezierEasing(1.0, 0.0, 0.7, 0.7)
    },
    delay: newsletterTriggers ? 0 : 300
  })

  const contentAnim = useSpring({
    opacity: newsletterTriggers ? 1 : 0,
    from: {
      opacity: 0
    },
    config: {
      duration: newsletterTriggers ? 600 : 800,
      easing: newsletterTriggers
        ? BezierEasing(0.165, 0.84, 0.44, 1.0)
        : BezierEasing(1.0, 0.0, 0.7, 0.7)
    },
    delay: 200
  })

  return (
    <>
      <NewsletterOuter className={newsletterTriggers ? 'active' : ''}>
        <CloseIcon
          style={closeIconAnim}
          aria-label="Toggle Navigation"
          onClick={() => {
            setNewsletterTriggers(false)
          }}
        >
          <Lines />
          <p>Close</p>
        </CloseIcon>
        <BgNav style={bgAnimation} />

        <WrapNewsletterContent style={contentAnim}>
          <SignUpTitle>{data.wordPress.globalOptions.optionFields.newsletter.title}</SignUpTitle>
          <NewsletterForm
            content={data.wordPress.globalOptions.optionFields.newsletter}
            fromSignup={false}
          />
        </WrapNewsletterContent>
      </NewsletterOuter>
    </>
  )
}

export default Newsletter

const NewsletterOuter = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  pointer-events: none;
  z-index: 121;

  &.active {
    pointer-events: all;
  }
`
const BgNav = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background-color: ${props => props.theme.colours.pink};
  will-change: transform;
`

const WrapNewsletterContent = styled(animated.div)`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 650px) {
    width: 100%;
  }
`

const SignUpTitle = styled.h5`
  font-family: ${props => props.theme.fonts.sofia};
  color: rgba(0, 0, 0, 0.25);
  font-size: 5.7rem;
  text-align: center;
  width: 150%;
  margin: 0 -25%;

  @media (max-width: 1360px) {
    font-size: 5rem;
  }

  @media (max-width: 1024px) {
    width: 100%;
    margin: 0;
  }
`

const Lines = styled.span`
  display: inline-block;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    right: 0;
    width: 3.5rem;
    height: 0.6rem;
    background-color: ${props => props.theme.colours.white};
    transform-origin: 50% 50%;
    transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:before {
    transform: translate(-50%, 50%) rotate(45deg);
    top: 50%;
    left: 50%;
  }

  &:after {
    transform: translate(-50%, 50%) rotate(-45deg);
    top: 50%;
    left: 50%;
  }
`

const CloseIcon = styled(animated.button)`
  position: absolute;
  top: 7.5rem;
  left: calc(50% - 3.4rem);
  width: 6.8rem;
  height: auto;
  border: 0;
  cursor: pointer;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 150;
  margin-top: 0;

  @media (min-width: 1700px) {
    top: 13rem;
  }

  @media (min-width: 1920px) {
    top: 10.9rem;
  }

  @media (max-width: 1300px) {
    top: 7.5rem;
  }

  @media (max-width: 1024px) {
    top: 10.8rem;
  }

  @media (max-width: 650px) {
    top: 8rem;
    left: calc(50% - 3.4rem);
    cursor: pointer;
  }

  p {
    margin-top: 25px;
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 700;
    letter-spacing: 0.2rem;
    color: ${props => props.theme.colours.white};
    font-size: 12px;
    text-transform: uppercase;
    transform: translateX(1px);

    @media (max-width: 650px) {
      transform: translateX(-1px);
    }
  }
`
