import React, { useState, useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useSpring, useTrail, animated } from 'react-spring'
import { Link, navigate, graphql, useStaticQuery } from 'gatsby'
import { TransitionContext } from './context/transition-context'
import KotaLogoWhite from '../images/Kota_logo_white.svg'

const config = { mass: 5, tension: 2000, friction: 200 }

const Header = () => {
  const { wordPress } = useStaticQuery(
    graphql`
      query {
        wordPress {
          globalOptions {
            optionFields {
              header {
                smallNav {
                  ... on WordPress_Page {
                    slug
                    title
                  }
                }
              }
              logo {
                image {
                  sourceUrl
                  mediaItemId
                  modified
                  altText
                  imageFile {
                    publicURL
                  }
                }
                imageWhite {
                  sourceUrl
                  mediaItemId
                  modified
                  altText
                  imageFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const nav = wordPress.globalOptions.optionFields.header.smallNav

  const [menuItemToggle, setMenuItemToggle] = useState(false)
  const [isHome, setIsHome] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)

  const ref = useRef()
  // eslint-disable-next-line no-unused-vars

  const spring = useSpring({
    config,
    transform: 'translateX(0px) scale(1)',
    opacity: 1,
    from: {
      transform: 'translateX(-25px) scale(0.75)',
      opacity: 0
    },
    delay: 500,
    onRest: () => setMenuItemToggle(true)
  })

  const trail = useTrail(nav.length, {
    config,
    opacity: menuItemToggle ? 1 : 0,
    transform: menuItemToggle ? 'translateX(0px)' : 'translateX(25px)',
    from: {
      transform: 'translateX(0px)',
      opacity: 0
    }
  })

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsHome(window.location.pathname === '/' || window.location.pathname === '')
    }
  }, [transitionActive])

  return (
    <Outer>
      <WrapHeader>
        <KotaLogo style={spring} ref={ref} className={isHome ? 'logoHome' : ''}>
          <Link
            to="/"
            onClick={event => {
              event.preventDefault()
              setTransitionActive(true)
              setTimeout(() => {
                navigate(`/`)
                setTransitionActive(false)
              }, 500)
            }}
          >
            <KotaLogoWhite />
          </Link>
        </KotaLogo>

        <HeaderNav>
          {trail.map(({ opacity, transform }, index) => (
            <WrapNavItem style={{ opacity, transform }} key={nav[index].title}>
              <NavItem
                to={`/${nav[index].slug}`}
                onClick={event => {
                  event.preventDefault()
                  setTransitionActive(true)
                  setTimeout(() => {
                    navigate(`/${nav[index].slug}`)
                    setTransitionActive(false)
                  }, 500)
                }}
                activeClassName="active"
              >
                {nav[index].title}
              </NavItem>
            </WrapNavItem>
          ))}
        </HeaderNav>
      </WrapHeader>
    </Outer>
  )
}

export default Header

const Outer = styled.header`
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  height: auto;
  padding: 5rem 0;
  font-family: ${props => props.theme.fonts.sofia};
  pointer-events: none;
  mix-blend-mode: exclusion;

  @media (max-width: 650px) {
    padding: 3.5rem 0;
  }
`

const WrapHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
  margin: 0 auto;
  transition: margin-top 0.2s ease-in-out;

  @media (max-width: 650px) {
    padding: 0 25px;
  }
`

const KotaLogo = styled(animated.div)`
  pointer-events: all;
  margin-top: 0;

  @media (max-width: 650px) {
    width: 45px;
  }

  &.logoHome {
    opacity: 0 !important;
  }

  &.banner-is-here {
    margin-top: 50px;
  }
  img {
    width: 60px;
    transition: transform 0.25s ease;
    margin-bottom: 0;

    @media (min-width: 1700px) {
      width: 90px;
    }
    &:hover {
      transform: scale(1.075);
    }
  }

  svg {
    width: 60px;
    transition: transform 0.25s ease;
    margin-bottom: 0;
    mix-blend-mode: multiply;

    @media (min-width: 1700px) {
      width: 90px;
    }
    &:hover {
      transform: scale(1.075);
    }

    @media (max-width: 650px) {
      width: 45px;
    }
  }
`

const HeaderNav = styled(animated.nav)`
  position: relative;
  margin-right: 8rem;
  pointer-events: all;

  @media (max-width: 650px) {
    display: none;
  }
`

const NavItem = styled(Link)`
  color: ${props => props.theme.colours.white};
  text-decoration: none;
  font-size: 2rem;
  transition: color 0.15s ease;
  mix-blend-mode: multiply;

  @media (max-width: 1024px) {
    font-size: 2.5rem;
  }

  &:hover {
    color: #11ab82;
    mix-blend-mode: unset !important;
  }

  &.active {
    mix-blend-mode: unset !important;
    color: #11ab82;
  }
`

const WrapNavItem = styled(animated.div)`
  display: inline-block;
  margin-right: 4.6rem;

  &:nth-child(2) {
    margin-right: 0;
  }
`
