import React, { useState, createContext } from 'react'

export const NewsletterContext = createContext()

export const NewsletterProvider = ({ children }) => {
  const [newsletterActive, setNewsletterActive] = useState(false)

  return (
    <NewsletterContext.Provider value={[newsletterActive, setNewsletterActive]}>
      {children}
    </NewsletterContext.Provider>
  )
}
