import React from 'react'
import { animated, useSprings, interpolate } from 'react-spring'
import { Link, navigate } from 'gatsby'
import BezierEasing from 'bezier-easing'
import styled from 'styled-components'

const Secondary = ({
  secondary,
  setToggle,
  setServicesToggle,
  servicesToggle,
  setTransitionActive
}) => {
  const navItemsAnimation = useSprings(
    secondary.length,
    secondary.map((item, i) => ({
      y: servicesToggle ? 0 : -30,
      rotate: servicesToggle ? 0 : 5,
      opacity: servicesToggle ? 1 : 0,
      from: { rotate: 5, y: -30, opacity: 0 },
      config: {
        duration: 300,
        easing: servicesToggle
          ? BezierEasing(0.25, 0.46, 0.45, 0.94)
          : BezierEasing(0.55, 0.06, 0.75, 0.54)
      },
      delay: servicesToggle ? i * 50 + 100 : i * 50 + 100
    }))
  )

  return (
    <WrapSecondaryNav>
      <ul style={{ margin: 0 }}>
        {navItemsAnimation.map(({ rotate, y, ...rest }, index) => (
          <animated.li
            key={[index]}
            style={{
              ...rest,
              transform: interpolate([y], y => `translateY(${y}px)`)
            }}
          >
            <Link
              activeClassName="active"
              to={`/services/${secondary[index].node.slug}`}
              onClick={event => {
                event.preventDefault()
                setServicesToggle(false)
                setTransitionActive(true)
                setToggle(false)
                navigate(`/services/${secondary[index].node.slug}`)
                setTimeout(() => {
                  setTransitionActive(false)
                }, 300)
              }}
            >
              {secondary[index].node.title}
            </Link>
          </animated.li>
        ))}
      </ul>
    </WrapSecondaryNav>
  )
}

export default Secondary

const WrapSecondaryNav = styled.div`
  position: absolute;
  left: 52.5%;
  top: 50%;
  transform: translateY(-50%);

  li {
    list-style: none;
    margin: 0;
    will-change: transform, opacity;

    a {
      font-size: 3rem;
      line-height: 1.5;
      letter-spacing: -0.1rem;
      color: rgba(0, 0, 0, 0.25);
      transition: color 300ms cubic-bezier(0.165, 0.84, 0.44, 1);

      &:hover {
        color: rgba(255, 255, 255, 1);
      }

      &.active {
        color: ${props => props.theme.colours.white};
      }
    }
  }
`
