import { createGlobalStyle } from 'styled-components'
import { resetStyles } from '../styles/reset'

const GlobalStyle = createGlobalStyle`
  ${resetStyles()}
  [data-scrollbar] {
    height: 100vh;
    width: 100vw;
  } 
  :root {
    --primary: #373737;
    --secondary: #ffffff;
    --textgrey: #878787;
    --darkgrey: #373737;
    --transition: background-color 0.4s ease-in-out 0s;
  }
  html {
    font-size: 10px;
    overflow: hidden;
    max-width: 100vw;
    background-color: #fff;
  }
  @media (min-width: 1920px) {
    html {
      font-size: 13px;
    }
  }
  @media (max-width: 1024px) {
    html {
      font-size: 8px;
    }
  }
  @media (max-width: 650px) {
    html {
      font-size: 7px;
    }
  }
  html.has-touch {
    overflow-y: scroll;
  }
  body {
    height: auto;
    width: 100vw;
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 300;
    line-height: 1.2;
    color: ${props => props.theme.colours.darkGrey};
    transition: background-color 0.4s ease-in-out;
    background-color: var(--secondary);
    transition: var(--transition);
  }
  body.has-touch [data-scrollbar] {
    height: auto;
    width: 100vw;
  }
  button:focus {
    outline:0;
  }

  * { 
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
  }

  h1, h2, h3 {
    letter-spacing: -0.075rem;
  }
  h1, h2, h3, li {
    font-family: ${props => props.theme.fonts.sofia};
    color: #fff;
  }
  a {
    color: ${props => props.theme.colours.pink};
    text-decoration: none;
  }
  p {
    font-family: ${props => props.theme.fonts.circular};
    font-size: 1.6rem;
    line-height: 1.6;
    color: ${props => props.theme.colours.pink};
    margin-bottom: 1.5rem;
  }
  @media (max-width: 1000px) {
    p {
      font-size: 2rem;
    }
  }
  .container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 119rem;
    padding: 0 3rem;
  }
  @media (max-width: 650px) {
    .container {
      padding: 0 4rem;
    }
  }
  .main {
    overflow: hidden;
  }
  .gatsby-image-wrapper * {
    transition: opacity 500ms ease 0s !important;
  }
  .scrollbar-track .scrollbar-thumb {
    background: #F74EA1;
  }
  [data-scrollbar] {
    z-index: 2;
  }
`

export default GlobalStyle
