import React, { useState, createContext } from 'react'

export const BannerContext = createContext()

export const BannerProvider = ({ children }) => {
  const [bannerActive, setBannerActive] = useState(false)

  return (
    <BannerContext.Provider value={[bannerActive, setBannerActive]}>
      {children}
    </BannerContext.Provider>
  )
}
