const theme = {
  colours: {
    black: '#000000',
    white: '#FFFFFF',
    pink: '#F74EA1',
    // lightGrey: '#C9C9C9',
    lightGrey: '#242424',
    // grey: '#878787',
    grey: '#242424',
    darkGrey: '#373737',
    footer: '#242424'
  },
  fonts: {
    sofia: `'Sofia', sans-serif`,
    circular: `'Circular', sans-serif`
  },
  sizes: {
    maxWidth: '80%'
  }
}

export default theme
